import React from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { SEO } from 'components'
import { useSizes } from 'hooks'

function Benefits() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { mdUp } = useSizes()
  const {
    benefitsGoat,
    benefit1,
    benefit2,
    benefit3,
    benefit4,
    benefit5,
    benefit6,
    benefit7,
    benefit8,
    benefit9
  } = useStaticQuery(graphql`
    query {
      benefitsGoat: file(relativePath: { eq: "benefitsGoat.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit1: file(relativePath: { eq: "benefits/benefit1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit2: file(relativePath: { eq: "benefits/benefit2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit3: file(relativePath: { eq: "benefits/benefit3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit4: file(relativePath: { eq: "benefits/benefit4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit5: file(relativePath: { eq: "benefits/benefit5.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit6: file(relativePath: { eq: "benefits/benefit6.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit7: file(relativePath: { eq: "benefits/benefit7.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit8: file(relativePath: { eq: "benefits/benefit8.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit9: file(relativePath: { eq: "benefits/benefit9.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const data = {
    benefitDescriptions: [
      {
        id: 1,
        title: '100PercentDairy',
        value: '100PercentDairyDescription'
      },
      {
        id: 2,
        title: 'easyToDigest',
        value: 'easyToDigestDescription'
      },
      {
        id: 3,
        title: 'lactoseIntolerant',
        value: 'lactoseIntolerantDescription'
      }
    ],
    benefitImgs: [
      {
        id: 1,
        img: benefit1.childImageSharp.fluid,
        description: 'benefit1'
      },
      {
        id: 2,
        img: benefit2.childImageSharp.fluid,
        description: 'benefit2'
      },
      {
        id: 3,
        img: benefit3.childImageSharp.fluid,
        description: 'benefit3'
      },
      {
        id: 4,
        img: benefit4.childImageSharp.fluid,
        description: 'benefit4'
      },
      {
        id: 5,
        img: benefit5.childImageSharp.fluid,
        description: 'benefit5'
      },
      {
        id: 6,
        img: benefit6.childImageSharp.fluid,
        description: 'benefit6'
      },
      {
        id: 7,
        img: benefit7.childImageSharp.fluid,
        description: 'benefit7'
      },
      {
        id: 8,
        img: benefit8.childImageSharp.fluid,
        description: 'benefit8'
      },
      {
        id: 9,
        img: benefit9.childImageSharp.fluid,
        description: 'benefit9'
      }
    ]
  }

  return (
    <>
      <SEO title={t('benefits')} />
      <Box display='flex' flexDirection='column' pt={2} px={mdUp ? 10 : 3}>
        <Box
          display='flex'
          flexDirection={mdUp ? 'row' : 'column'}
          justifyContent='space-between'
          alignItems='center'
          pb={5}
          mb={5}
          className={classes.titleContainer}
        >
          <Box display='flex' flexDirection='column'>
            <Typography variant={mdUp ? 'h3' : 'h5'} className={classes.title}>
              {t('goatMilkBenefits').toUpperCase()}
            </Typography>
            <Typography variant='h6' className={classes.titleDescription}>
              {t('goatMilkBenefitsDescription')}
            </Typography>
          </Box>

          <Box display='flex' px={4} py={2}>
            <Img
              fluid={benefitsGoat.childImageSharp.fluid}
              alt='Benefit goat'
              className={classes.img}
            />
          </Box>
        </Box>

        {data.benefitDescriptions.map(({ id, title, value }) => {
          return (
            <Box key={id} display='flex' flexDirection='column' py={2}>
              <Typography variant='h6' className={classes.benefitTitle}>
                {t(title)}
              </Typography>

              <Typography variant='h6' className={classes.benefitDescription}>
                {t(value)}
              </Typography>
            </Box>
          )
        })}

        <Grid container justify='center' alignItems='stretch'>
          {data.benefitImgs.map(({ id, img, description }) => {
            return (
              <Grid item xs={12} md={4} key={id}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  py={2}
                  flexDirection='column'
                >
                  <Img
                    fluid={img}
                    alt='Benefit image'
                    className={classes.benefitImg}
                  />

                  <Typography
                    variant='h6'
                    align='center'
                    className={classes.benefitImgDescription}
                  >
                    {t(description).toUpperCase()}
                  </Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  titleContainer: {
    borderBottom: 'solid',
    borderBottomColor: theme.palette.primary.main
  },
  img: {
    width: 300
  },
  title: {
    color: theme.palette.secondary.light,
    fontWeight: 'bold',
    marginBottom: theme.spacing(4)
  },
  titleDescription: {
    color: theme.palette.silver.main
  },
  benefitTitle: {
    border: 'solid',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(1),
    fontWeight: 'bold'
  },
  benefitDescription: {
    color: theme.palette.silver.main,
    padding: theme.spacing(0, 1)
  },
  benefitImg: {
    width: 200,
    marginBottom: theme.spacing(3)
  },
  benefitImgDescription: {
    color: theme.palette.secondary.light
  }
}))

export default Benefits
